import "./App.css";

function App() {
  const titles = ["Buy $KWAK", "About", "Gallery"];

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  const callbacks = [
    () => scrollToSection("buy-kwak"),
    () => scrollToSection("about"),
    () => scrollToSection("gallery"),
  ];

  const handleImageClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="App">
      <Header titles={titles} callbacks={callbacks}></Header>
      <Section>
        <Marquee text="  $ KWAK" direction="left"></Marquee>
        <img src="./logo-site.png" className="App-logo" alt="logo" />
        <Marquee text="  $ KWAK" direction="left"></Marquee>
      </Section>
      <Section2>
        <div id="about"></div>
        <div className="twocolumns">
          <img
            src="kwak_images/buddha.png"
            alt="kwak pump"
            className="twocolumns-image"
          />
          <div className="twocolumns-text">
            <h1>About</h1>
            Introducing $Kwak, a memecoin featuring a badass duck puffing on a
            big cigar like a true gang boss. It’s backed by some well-known
            heavyweights. $Kwak was made to go viral, and you better believe it
            will. Go grab some! The cartel’s gonna win!
          </div>
        </div>
      </Section2>
      <Section>
        <div id="buy-kwak"></div>
        <h1>Buy $KWAK</h1>
        <img src="kwak_images/buy.gif" className="buy-gif "></img>
        <span className="center padding">
          CA : 12sCFPJSfe8PXE1aiW28g6ZskJ7w7kKsiAvVzLUXpump
        </span>
        <div className="flex-row">
          <img
            className="logo"
            src="pumpfun.png"
            alt="Pumpfun"
            onClick={() => handleImageClick("https://pump.fun/")}
          />
          <img
            className="logo"
            src="jupiter.png"
            alt="Jupiter"
            onClick={() => handleImageClick("https://jup.ag/")}
          />
          <img
            className="logo"
            src="dexscreener.png"
            alt="Dexscreener"
            onClick={() => handleImageClick("https://dexscreener.com/")}
          />
          <img
            className="logo"
            src="dextools.svg"
            alt="Dextools"
            onClick={() => handleImageClick("https://www.dextools.io/")}
          />
        </div>
      </Section>
      <Section2>
        <div id="gallery"></div>
        <h1>Gallery</h1>
        <Gallery></Gallery>
      </Section2>
    </div>
  );
}

function Section({ children }) {
  return (
    <div className="section">
      <img
        className="separator top-separator"
        src="separator.png"
        alt="separator"
      />
      <div className="content">{children}</div>
      <img
        className="separator bottom-separator"
        src="separator2.png"
        alt="separator"
      />
    </div>
  );
}

function Section2({ children }) {
  return <div className="content">{children}</div>;
}

function Marquee({ text, direction = "left", speed = 3600 }) {
  const repeatedText = Array(2000).fill(text).join("    ");

  return (
    <div
      className={`marquee-container ${direction}`}
      style={{ "--speed": `${speed}s` }}
    >
      <div className="marquee">
        <span>{repeatedText}</span>
      </div>
    </div>
  );
}

const Gallery = () => {
  const images = [
    "/kwak_images/buddha.png",
    "/kwak_images/buy.gif",
    "/kwak_images/eddy.png",
    "/kwak_images/GJwBzUXW4AAi_QL.png",
    "/kwak_images/GLMiHB1WAAAytVk.jpeg",
    "/kwak_images/GLstGbfW8AAbyw9.png",
    "/kwak_images/GM6AzWAXMAABhfu.jpeg",
    "/kwak_images/GMjrSDRW4AAbo-6.jpeg",
    "/kwak_images/GMLop2iWQAApl7V.jpeg",
    "/kwak_images/GMUN1QzWMAA2CWa.png",
    "/kwak_images/GNEN1zyXsAAsfwR.jpeg",
    "/kwak_images/GNxCWhyWEAA8zcD.jpeg",
    "/kwak_images/GPDPf3TWUAASJ5Y.jpeg",
    "/kwak_images/GReAnmyXEAA_XOi.jpeg",
    "/kwak_images/GRjGA1MXgAAlaro.jpeg",
    "/kwak_images/GRtZ2HgXgAE7o1Q.png",
    "/kwak_images/GT6M-WeXIAot3wn.png",
    "/kwak_images/GTBLWXLXMAAPWqN.png",
    "/kwak_images/GUxCzQsXEAAasHS.jpeg",
    "/kwak_images/GVAMxPrWEAAfGOy.png",
    "/kwak_images/GVulpc0WkAAALTB.jpeg",
    "/kwak_images/GWscD4mWgAAtXkb.jpeg",
    "/kwak_images/GWxzjNVXkAAp5FJ.jpeg",
    "/kwak_images/GYJP0OOXYAAwFXe.jpeg",
    "/kwak_images/GYJtl9YWUAAY90b.jpeg",
    "/kwak_images/GYUbVMgXcAEDp4y.jpeg",
    "/kwak_images/GZBvup_WQAA679v.jpeg",
    "/kwak_images/head.png",
    "/kwak_images/stonks.png",
    "/kwak_images/GN2Bft_WgAArIzs.jpeg",
  ];

  return (
    <div className="gallery">
      {images.map((src, index) => (
        <img key={index} src={src} alt={`image-${index}`} />
      ))}
    </div>
  );
};

function Header({ titles, callbacks }) {
  if (titles.length !== callbacks.length) {
    return null;
  }

  return (
    <div className="header">
      {titles.map((title, index) => (
        <span key={index} className="header-button" onClick={callbacks[index]}>
          {title}
        </span>
      ))}
    </div>
  );
}

export default App;
